<template>
  <div>
    <isHeader ref="isHeader" />
    <div
      ref="scrollBox"
      style="height: 100vh; overflow-y: auto; overflow-x: hidden"
    >
      <commonTop
        :info="{
          title: '生态招募',
          desc: '携手华信互联共创健康管理生态社区',
          show_desc: true,
        }"
        :img="require('@/assets/EcologicalRecruitment/banner1.png')"
      >
        <!-- 插槽 -->
      </commonTop>
      <!--  -->
      <isCom>
        <!-- <isTab
          class="isTab"
          style="width: 100vw"
          :hide_tab="true"
          current="生态招募"
          :info="info"
        /> -->
      </isCom>
      <isFooter />
    </div>
  </div>
</template>

<script>
import isHeader from "@/components/header/index.vue";
import isFooter from "@/components/footer/index.vue";
import commonTop from "@/components/commonTop/index.vue";
// import isTab from "@/components/ProductTab/tab.vue";
import isCom from "@/components/EcologicalRecruitment/com.vue";
export default {
  name: "EcologicalRecruitment",
  components: {
    isCom,
    commonTop,
    isHeader,
    isFooter,
    // isTab,
  },
  data() {
    return {
      info: {
        partOne: false,
        partTwo: {
          title: "招募方向",
          sub: "生态伙伴的方向",
          list: [
            {
              title: "双方业务具有较强关联性的软、硬件产品厂商",
              desc: "合作方式：与我们在营销、渠道和产品生态层面展开各类合作",
            },
            {
              title: "医疗IT行业传统软硬件供集成商、代理商",
              desc: "合作方式：产品代理、渠道销售",
            },
            {
              title: "药品生产厂商、医药公司 ",
              desc: "合作方式：我们可以在营销渠道、数据运营、数字营销层面展开合作",
            },
            {
              title: "医疗器械、耗材厂商或代理商",
              desc: "合作方式：我们可以在营销、渠道，以及耗材营销层面展开合作",
            },
            {
              title: "有意拓展医疗业务的银行企业",
              desc: "合作方式：与我们在To G项目展开合作，To C方向上向C端患者市场推广层面展开合作",
            },
            {
              title: "综合类贸易伙伴（聚焦在医疗行业，拥有较广的人脉网络）",
              desc: "合作方式：产品代理、渠道销售",
            },
          ],
          bg: require("@/assets/EcologicalRecruitment/bg.png"),
          right_img: require("@/assets/EcologicalRecruitment/swiper_right_img.png"),
        },
      },
    };
  },
  mounted() {
    // this.Message({
    //   message:"message"
    // });

    let that = this;
    this.$refs.scrollBox.addEventListener("scroll", function (e) {
      if (e.target.scrollTop > 80) {
        that.$refs.isHeader.setScrollBg(true);
      } else {
        that.$refs.isHeader.setScrollBg(false);
      }
    });
  },
};
</script>

<style>
.isTab {
  margin-left: -1.9rem;
}
@media screen and (max-width: 1024px) {
  .isTab {
    margin-left: -20px !important;
  }
}
</style>