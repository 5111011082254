<template>
  <div class="box" style="">
    <!--  -->
    <div
      v-runshow
      class="flex j_c_c a_i_s top_com"
      style="background: #fff; margin: 0.6rem 0"
    >
      <div style="width: 58%; box-sizing: border-box; padding-right: 20px">
        <div class="new_title flex a_i_c is_title" style="">
          <span
            style="
              display: inline-block;
              background: #1aa858;
              width: 2px;
              height: 20px;
              margin-right: 4px;
            "
          ></span>
          华信互联期待与您携手共进！
        </div>
        <div class="flex j_c_c">
          <div class="tips" style="margin: 0 auto"></div>
        </div>

        <div class="is_1_desc" style="">
          华信互联愿与生态伙伴一道，致力于为客户构建完整的全程健康管理解决方案，不论在上游，还是在下游，我们都保持与生态伙伴紧密协作，不断拓展商业渠道和业务边界，为最终客户提供多样化的解决方案场景的同时，让伙伴受益。
        </div>
        <div class="is_1_desc">
          生态伙伴是华信互联持续发展的重要驱动力，我们也通过自身价值链，真诚的、有温度感的赋能生态伙伴，助力伙伴走向成功。
          如果您的公司在区域、医疗垂直细分领域耕耘多年，并积累了一定的资源，但在业务发展方向上又存在瓶颈，您可以携手华信互联，共谋发展大业。
        </div>
      </div>
      <img
        v-runcentershow
        style="width: 42%"
        :src="require('@/assets/EcologicalRecruitment/isimg.png')"
        alt=""
      />
    </div>
    <!--  -->
    <div class="big_size" style="background: #f7f7fa">
      <div class="flex flex_d j_c_ a_i_c" v-runshow>
        <div class="title">生态、渠道的等级</div>
        <div class="tips"></div>
      </div>
      <div class="wrap" v-runshow>
        <div
          v-runcentershow
          class="item_wrap flex flex_d j_c_c a_i_c"
          v-for="(item, index) in list"
          :key="index"
        >
          <div class="is_icon" v-html="item.icon"></div>
          <div class="name">
            {{ item.name }}
          </div>
          <div class="tips"></div>
          <div class="desc">
            {{ item.desc }}
          </div>
        </div>
      </div>

      <!--  -->
      <div v-runshow class="wrap_mobile">
        <div
          class="is_4_com_swiper"
          style="position: relative; padding-bottom: 40px"
        >
          <div class="swiper-wrapper" style="">
            <div
              class="swiper-slide"
              v-for="(item, index) in list"
              style="width: 100vw"
              :key="index"
            >
              <div class="item_wrap flex flex_d j_c_c a_i_c">
                <div
                  class="is_icon"
                  v-html="item.icon"
                  style="width: 50px; height: 50px"
                ></div>
                <div class="name">
                  {{ item.name }}
                </div>
                <div class="tips"></div>
                <div class="desc">
                  {{ item.desc }}
                </div>
              </div>
            </div>
          </div>
          <!-- 如果需要分页器 -->
          <div
            style=""
            class="is_4_com_swiper_agination swiper-pagination"
          ></div>
        </div>
      </div>
    </div>

    <!--  -->
    <!-- 曾经是个轮播图,现在改为普通排版 -->
    <!-- <slot></slot> -->
    <div style="" class="flex flex_d j_c_ a_i_c" v-runshow>
      <div class="part_two_title">招募方向</div>
      <div class="title is_02_title">我们对生态伙伴的赋能</div>
      <div class="tips"></div>
    </div>
    <div
      v-runshow
      class="flex j_c_c a_i_s top_com"
      style="background: #fff; margin-top: 40px; margin-bottom: 30px"
    >
      <div style="width: 58%; box-sizing: border-box; padding-right: 20px">
        <div
          v-for="(item, index) in recruitList"
          :key="index"
          class="recruit_item"
        >
          <div class="flex a_i_c childTitle" style="">
            <span style="">
              <svg
                width="100%"
                height="100%"
                viewBox="0 0 15 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.26677 0H0L8.73345 8.73345C9.73347 9.73347 11.2668 9.73347 12.2668 8.73345L14.1335 6.86676L7.26677 0Z"
                  fill="#1AA858"
                />
                <path
                  d="M0.199219 24.0005H7.46599L14.1327 17.2671L12.2661 15.4004C11.266 14.4004 9.73269 14.4004 8.73267 15.4004L0.199219 24.0005Z"
                  fill="#1AA858"
                />
              </svg>
            </span>
            {{ item.title }}
          </div>
          <div class="is_2_desc" style="">
            {{ item.desc }}
          </div>
        </div>
      </div>
      <img
        v-runcentershow
        style="width: 42%"
        :src="require('@/assets/EcologicalRecruitment/swiper_right_img.png')"
        alt=""
      />
    </div>

    <!--  -->
    <div class="new_big_size">
      <div style="" class="flex flex_d j_c_ a_i_c" v-runshow>
        <div class="part_two_title" style="">合作共赢</div>
        <div class="title is_02_title">我们对生态伙伴的赋能</div>
        <div class="tips"></div>
      </div>
      <div style="" class="card_box" v-runshow>
        <div
          v-runcentershow
          class="item_card"
          v-for="(item, index) in card"
          :key="index"
        >
          <div class="card_title">
            {{ item.title }}
          </div>
          <div class="card_desc">
            {{ item.desc }}
          </div>
        </div>
        <div class="clear"></div>
      </div>
    </div>

    <!--  -->
    <div style="background: #fff" class="flex flex_d j_c_c a_i_c">
      <div class="is_03_title" style="">在线留言</div>
      <div class="is_03_desc" style="">期待您的加入</div>
      <div class="tips"></div>
    </div>
    <div class="flex a_i_s j_c_b flex_wrap lastcom" style="margin-top: 30px">
      <div class="flex_1">
        <div class="item_row">
          <span>*</span>
          <input v-model="form.name" type="text" placeholder="姓名" />
        </div>
        <div class="item_row">
          <span>*</span>
          <input v-model="form.phone" type="text" placeholder="联系方式" />
        </div>
        <div class="item_row">
          <span>*</span>
          <input v-model="form.company" type="text" placeholder="公司" />
        </div>
        <div class="item_row">
          <textarea
            v-model="form.remark"
            placeholder="你的留言"
            name=""
            id=""
            cols="30"
            rows="10"
          ></textarea>
        </div>
        <div class="flex a_i_c">
          <div class="item_row item_row_code">
            <span style="color: red; font-size: 12px">*</span>
            <input
              v-model="code"
              class="code_input"
              type="text"
              placeholder="验证码"
            />
          </div>
          <div class="code_number" @click="reNewCode">{{ realCode }}</div>
        </div>
        <div>
          <button class="submit_btn" @click="appointSubmit()">立即提交</button>
        </div>
      </div>
      <div class="right_ctx_box">
        <div class="right_ctx">
          <div class="label">公司地址</div>
          <div>南京市建邺区泰山路 159 号正太中心大厦 B 座 1001 室</div>
          <div class="label">商务合作</div>
          <div style="margin-bottom: 4px">施女士：18810905799</div>
          <div>曲女士：17368717285</div>

          <img
            class="map_img"
            :src="require('@/assets/EcologicalRecruitment/right_map.png')"
            style="margin-top: 50px"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      realCode: "",
      code: "",
      form: {
        name: "",
        phone: "",
        company: "",
        remark: "",
      },
      card: [
        {
          title: "海纳计划训练营",
          desc: "产品沉浸式学习、案例多维度讲解、全场景模拟演练",
        },
        {
          title: "专项活动指导",
          desc: "制定区域专项事项（场景式推介会、品鉴会、技术沙龙等）",
        },
        {
          title: "销售队伍",
          desc: "协助下游伙伴建立专业销售队伍",
        },
        {
          title: "商机挖掘",
          desc: "共同挖掘商业机会，联合制定商业计划",
        },
        {
          title: "资金支持",
          desc: "专项资金支持",
        },
        {
          title: "营销支持",
          desc: "全程营销工具支持",
        },
        {
          title: "招投标支持",
          desc: "招投标过程支持",
        },
        {
          title: "签约授权",
          desc: "协议证书签约，授权牌发放",
        },
      ],
      list: [
        {
          name: "战略级",
          desc: "产品整合+渠道代理型，伙伴双方的产品在技术和渠道方面均可以深度整合，形成创新型的解决方案，并通过双方的渠道相互拉动销售",
          icon: `<svg style="width:100%;height:100%;" viewBox="0 0 87 98" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 35.4453H14.3333" stroke="#61646B" stroke-width="3" stroke-miterlimit="10"/>
                    <path d="M7.50781 27.9375V42.9534" stroke="#61646B" stroke-width="3" stroke-miterlimit="10"/>
                    <path d="M60.7457 42.2695L45.7298 56.6029L31.3965 42.2695" stroke="#1AA858" stroke-width="3" stroke-miterlimit="10"/>
                    <path d="M7.50781 22.4763C7.50781 19.7462 7.50781 18.3811 7.50781 18.3811C34.1269 17.6986 45.73 3.36523 45.73 3.36523C45.73 3.36523 57.3332 17.6986 84.6348 18.3811C84.6348 18.3811 84.6348 46.3652 84.6348 54.5557C84.6348 62.7462 70.984 90.7303 45.73 95.5081C20.4761 90.7303 7.50781 62.7462 7.50781 54.5557C7.50781 51.8255 7.50781 50.4605 7.50781 49.0954" stroke="#61646B" stroke-width="3" stroke-miterlimit="10"/>
                    </svg>
                    `,
        },
        {
          name: "钻石级",
          desc: "渠道代理，按套或者按年销售额签订协议",
          icon: `<svg style="width:100%;height:100%;" viewBox="0 0 99 96" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M35.2246 9.27148H48.6413" stroke="#61646B" stroke-width="3" stroke-miterlimit="10"/>
                    <path d="M41.6133 2.24414V15.6608" stroke="#61646B" stroke-width="3" stroke-miterlimit="10"/>
                    <path d="M28.8333 9.27148H21.8056L2 36.1048L49.2778 92.327L96.5556 36.1048L76.75 9.27148H54.3889" stroke="#61646B" stroke-width="3" stroke-miterlimit="10"/>
                    <path d="M62.6968 34.1875L49.2802 47.6042L35.2246 34.1875" stroke="#61646B" stroke-width="3" stroke-miterlimit="10"/>
                    </svg>
                    `,
        },
        {
          name: "白金级",
          desc: "渠道代理，一单一策",
          icon: `<svg style="width:100%;height:100%;" viewBox="0 0 92 75" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M31.2012 6.64453H43.8857" stroke="#61646B" stroke-width="3" stroke-miterlimit="10"/>
                    <path d="M37.2422 0V12.6846" stroke="#61646B" stroke-width="3" stroke-miterlimit="10"/>
                    <path d="M58.9868 33.2207L46.3023 45.9053L33.0137 33.2207" stroke="#61646B" stroke-width="3" stroke-miterlimit="10"/>
                    <path d="M51.1352 6.64453H72.8801L89.7929 73.0875H2.20898L19.7258 6.64453H25.162" stroke="#61646B" stroke-width="3" stroke-miterlimit="10"/>
                    </svg>
                    `,
        },
      ],
      recruitList: [
        {
          title: "双方业务具有较强关联性的软、硬件产品厂商",
          desc: "合作方式：与我们在营销、渠道和产品生态层面展开各类合作",
        },
        {
          title: "医疗IT行业传统软硬件供集成商、代理商",
          desc: "合作方式：产品代理、渠道销售",
        },
        {
          title: "药品生产厂商、医药公司 ",
          desc: "合作方式：我们可以在营销渠道、数据运营、数字营销层面展开合作",
        },
        {
          title: "医疗器械、耗材厂商或代理商",
          desc: "合作方式：我们可以在营销、渠道，以及耗材营销层面展开合作",
        },
        {
          title: "有意拓展医疗业务的银行企业",
          desc: "合作方式：与我们在To G项目展开合作，To C方向上向C端患者市场推广层面展开合作",
        },
        {
          title: "综合类贸易伙伴（聚焦在医疗行业，拥有较广的人脉网络）",
          desc: "合作方式：产品代理、渠道销售",
        },
      ],
    };
  },
  created() {
    this.reNewCode();
  },
  mounted() {
    var mySwiper = new Swiper(".is_4_com_swiper", {
      direction: "horizontal", // 垂直切换选项
      loop: true, // 循环模式选项
      // 如果需要分页器
      pagination: {
        el: ".is_4_com_swiper_agination",
      },
    });
  },
  methods: {
    random(a, b) {
      return Math.floor(Math.random() * (b - a + 1)) + a;
    },
    reNewCode() {
      let codeStr =
        "abcdefghijklmnopqrstuvwxyz0123456789" +
        "abcdefghijklmnopqrstuvwxyz".toLocaleUpperCase();
      let realCode = "";
      for (var i = 0; i < 4; i++) {
        let text = codeStr[this.random(0, codeStr.length - 1)];
        realCode += text;
      }
      this.realCode = realCode;
    },
    appointSubmit() {
      const _this = this;
      if (this.code.toLowerCase() === this.realCode.toLowerCase()) {
        if (this.form.name && this.form.phone && this.form.company) {
          $.ajax({
            type: "POST",
            url: "/server/experience/addExperience",
            data: this.form,
            success: function (msg) {
              _this.$message(msg);
            },
          });
        } else {
          this.$message({
            message: "请将联系信息填写完整",
            type: "warning",
          });
        }
      } else {
        this.$message({
          message: "验证码错误",
          type: "error",
        });
        this.reNewCode();
        this.code = "";
      }
    },
  },
};
</script>

<style scoped>
.lastcom {
  margin-top: 1rem !important;
}
.big_size {
  width: 100vw;
  box-sizing: border-box;
  margin-left: -1.9rem;
  padding: 1.2rem 1.9rem;
}
.is_03_title {
  margin-top: 1.2rem;
  color: #888;
  font-size: 0.3rem;
  line-height: 0.42rem;
}
.is_03_desc {
  margin: 10px 0;
  color: #333;
  margin-bottom: 20px;
  font-size: 0.38rem;
  line-height: 0.53rem;
}

.part_two_title {
  font-size: 0.2rem;
  font-weight: 800;
  color: #888;
  margin-top: 100px;
}
.is_1_desc {
  font-weight: 400;
  font-size: 0.18rem;
  line-height: 0.36rem;
  margin-top: 0.4rem;
}
.is_2_desc {
  font-weight: 400;
  font-size: 0.18rem;
  line-height: 0.36rem;
  margin-bottom: 0.38rem;
  color: #61646b;
  padding-left: 0.19rem;
}
.is_title {
  font-weight: 600;
  font-size: 0.3rem;
  line-height: 0.42rem;
}
.recruit_item:hover > .childTitle,
.recruit_item:hover > .is_2_desc {
  color: #1aa858;
}
.recruit_item:hover > .childTitle > span {
  opacity: 1;
}
.recruit_item:hover > .is_2_desc {
  color: #1aa858;
}
.childTitle {
  font-weight: 600;
  font-size: 0.26rem;
  line-height: 0.32rem;
  margin-bottom: 0.1rem;
  color: #373948;
}
.childTitle span {
  transition: all 0.4s !important;
  display: inline-block;
  opacity: 0;
  width: 0.15rem;
  height: 0.24rem;
  margin-right: 4px;
}
.is_title span {
  height: 0.3rem !important;
  width: 3px !important;
  margin-right: 0.04rem;
}
.flex_1 {
  flex: 1;
}
.right_ctx_box {
  padding-left: 60px;
  width: 42vw;
}
.right_ctx {
  width: 40vw;
  font-size: 16px;
  color: #555;
  border: 1px solid #e5e5e5;
  padding: 20px 40px;
  box-sizing: border-box;
}
.label {
  color: #999;
  font-size: 14px;
  line-height: 180%;
  margin-top: 20px;
}
.map_img {
  width: 100%;
  margin-top: 0px;
}
/* .code_input {
  background: #f7f7fa;
  width: 200px !important;
  flex: none;
  margin-right: 30px;
} */
button {
  border: none;
}
.noobg_row {
  background: transparent !important;
}
.code_number {
  cursor: pointer;
  color: #888;
  background: #f7f7fa;
  width: 100px !important;
  flex: none;
  padding: 0px 6px;
  text-align: center;
  margin-bottom: 20px;
  margin-left: 20px;
  font-size: 14px;
  height: 32px;
  line-height: 32px;
  box-sizing: border-box;
  border-radius: 6px;
}
.submit_btn {
  background: #1aa858;
  color: #fff;
  padding: 10px 50px;
  border-radius: 4px;
  cursor: pointer;
}
textarea,
input {
  background: rgba(0, 0, 0, 0);
  flex: 1;
  outline: none;
  border: none;
  padding: 10px;
  box-sizing: border-box;
}
.item_row {
  align-items: flex-start;
  padding: 0 10px;
  margin-bottom: 20px;
  background: #f7f7fa;
  border-radius: 6px;
  display: flex;
  width: 100%;
  box-sizing: border-box;
}
.item_row_code {
  width: 50%;
}
.item_row > span {
  color: red;
  margin-top: 8px;
  font-size: 14px;
}
/*  */
.top_com .tips {
  display: none;
}
.clear {
  clear: both;
}
.card_box {
  margin-top: 40px;
  margin-bottom: 60px;
}
.item_card {
  float: left;
  /* width: calc(25% - 12px); */
  width: 3.68rem;
  height: 1.94rem;
  margin: 0.08rem;
  background: #fff;
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  padding: 30px;
  /* height: 140px; */
  transition: all 1s ease !important;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.item_card:hover {
  background: #1aa858;
  color: #fff;
  cursor: pointer;
}
.item_card:hover .card_title {
  color: #fff;
}
.item_card:hover .card_desc {
  color: #fff;
}
.card_title {
  text-align: center;
  font-size: 18px;
  font-weight: 800;
  margin-bottom: 10px;
  color: #444;

  font-size: 0.29rem;
  line-height: 0.41rem;
}
.card_desc {
  font-size: 12px;
  color: #666;
  text-align: center;
  line-height: 160%;
  font-size: 0.18rem;
  line-height: 0.3rem;
}
.wrap {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  margin-top: 60px;
}
.wrap_mobile {
  height: 0px;
  overflow: hidden;
}
.item_wrap {
  margin: 0 20px;
}
.name {
  font-size: 20px;
  font-weight: 800;
  color: #000;
  margin-top: 20px;
  margin-bottom: 4px;
}
.desc {
  text-align: center;
  font-size: 12px;
  color: #666;
  margin-top: 20px;
  letter-spacing: 1px;
  width: 3.84rem;
}
/* .title {
  font-size: 26px;
  letter-spacing: 2px;
  margin: 16px 0;
  color: #21252e;
} */
.tips {
  width: 70px;
  height: 3px;
  background: #1aa858;
}
.box {
  padding: 0 1.9rem 1.2rem 1.9rem;
}
.is_left_label {
  font-size: 18px;
}
.is_left_label div {
  margin-left: 10px;
}

.wrap_mobile {
  padding-top: 40px;
}
.is_4_com_swiper-pagination >>> .swiper-pagination-bullet {
  background: #1aa858;
}
.new_big_size {
  width: 100vw;
  padding: 0 1.9rem;
  margin-left: -1.9rem;
  padding-bottom: 2px;
  box-sizing: border-box;
  background: #f7f7fa;
}
.is_icon {
  width: 0.9rem;
  height: 0.9rem;
}
.title {
  font-size: 0.38rem;
  letter-spacing: 2px;
  margin: 16px 0;
  color: #21252e;
}
.name {
  font-size: 0.28rem;
  line-height: 0.39rem;
  font-weight: 800;
  color: #000;
  margin-top: 20px;
  margin-bottom: 4px;
}
.tips {
  width: 70px;
  height: 3px;
  background: #1aa858;
}
.desc {
  text-align: center;
  font-size: 0.18rem;
  color: #666;
  margin-top: 20px;
  letter-spacing: 1px;
  width: 3.84rem;
}
@media screen and (max-width: 1024px) {
  .title {
    font-size: 0.48rem;
    line-height: 0.67rem;
    /* margin-top: 1rem; */
  }
  .desc {
    width: 6rem !important;
    margin-top: 0.56rem;
  }
  .item_wrap .tips {
    width: 0.84rem;
    height: 0.03rem;
  }
  .name {
    font-size: 0.48rem;
    line-height: 0.67rem;
  }
  .is_icon {
    width: 1.52rem !important;
    height: 1.52rem !important;
  }
  .new_big_size {
    width: 100vw;
    padding: 0 20px;
    margin-left: -20px;
    padding-bottom: 2px;
    box-sizing: border-box;
    background: #f7f7fa;
  }
  .lastcom .right_ctx_box {
    width: 100%;
    padding: 0;
  }
  .lastcom > div:nth-child(1) {
    width: calc(100vw - 60px);
    flex: none;
  }
  .right_ctx {
    width: 100%;
    margin: 20px 0 !important;
    padding: 20px;
  }
  .top_com {
    flex-wrap: wrap;
    /* flex-direction: column; */
    /* flex-direction: column-reverse; */
  }
  .new_title {
    text-align: center;
    /* width: 100%; */
    box-sizing: border-box;
    justify-content: center;
  }
  .new_title span {
    display: none;
  }
  .top_com .is_title span {
    display: none !important;
  }
  .top_com .is_title {
    margin: 20px 0;
    line-height: 0.48rem;
    text-align: center;
    font-size: 0.38rem;
    padding: 0 40px;
  }
  .is_1_desc {
    font-size: 0.28rem;
    line-height: 0.48rem;
  }
  .top_com .tips {
    display: inline-block;
    margin-bottom: 20px;
  }
  .top_com > div {
    width: 100% !important;
    padding-right: 0 !important;
    margin-bottom: 10px;
  }
  .top_com > img {
    width: 100% !important;
  }
  .box {
    padding: 20px;
  }
  .wrap {
    display: none;
  }
  .wrap_mobile {
    height: auto;
    overflow: visible;
  }
  .part_two_title {
    margin-top: 40px !important;
    font-size: 0.48rem;
  }
  .item_card {
    width: 47.8%;
    margin: 1%;
    padding: 20px 10px;
    height: 120px;
  }
  .card_title {
    font-size: 16px;
  }
  .card_desc {
    font-size: 12px !important;
  }
  .big_size {
    margin-left: -20px;
    padding: 1.2rem 0;
  }
}
/* @media screen and (min-width:1910px) {
    .is_title{
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
        line-height: 42px;
    }
    .is_title span{
        width: 3px!important;
        height: 30px!important;
    }
    .is_1_desc{
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 36px;
    }
    .top_com{
        margin-top: 80px;
    }
    .title{
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 38px;
        line-height: 53px;
        margin-top: 80px;
    }
    .name{
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 900;
        font-size: 28px;
        line-height: 39px;
    }
    .desc{
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 30px;
        width: 484px;
        margin-top: 40px;
        margin-bottom: 80px;
    }
    .wrap{
        padding: 0 40px;
    }
    .top_com{
        padding: 0px 80px;
    }
    .is_02_title{
        margin-top: 20px;
        padding-top: 0;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 38px;
        line-height: 53px;
    }
    .item_card{
        width: 373px;
        height: 194px;
    }
    .card_box{
        padding: 0 80px;
    }

    .card_title{
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 900;
        font-size: 29px;
        line-height: 41px;
        margin-top:10px;
    }
    .card_desc{
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 30px;
        text-align: center;
    }
    .is_03_title{
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 900;
        font-size: 30px;
        line-height: 42px;
    }
    .is_03_desc{
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 38px;
        line-height: 53px;
    }
    .lastcom{
        margin-top: 60px!important;
        margin-left: 90px;
    }
} */
</style>
